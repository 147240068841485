<template lang="">
  <div>
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="8">
          <form-v-select
            ref="vendor"
            v-model="vendor"
            dataurl="vendor?filter[approval_status]=1"
            col-label="3"
            label="Vendor"
            placeholder="Semua Vendor"
            :item_text="'name'"
          />
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click.prevent="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click.prevent="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :is-edit="false"
        :is-delete="false"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="mr-1"
            size="sm"
            variant="success"
            @click="showFilter =!showFilter"
          >
            <feather-icon icon="FilterIcon" />
            Filter
          </b-button>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard, BButton, BFormSelect, BFormGroup, BRow,BCol, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    BFormSelect,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    FormVSelect,
    BInputGroup,
    BInputGroupAppend
  },
  data() {
    return {
      dataurl:"/contract",
      baseroute:"contract",
      title:"Daftar Kontrak",
      fields: [
        { key: 'number', label: 'Nomor', sortable: true },
        { key: 'subject', label: 'Nama', sortable: true },
        { key: 'vendor.name', label: 'Vendor', sortable: true },
        { key: 'date', label: 'Tanggal Kontrak', sortable: true },
      ],
      vendor:null,
      status:null,
      year:null,
      showFilter : false,
      filter :[],
    }
  },
  methods:{
    show(){
      this.filter['filter[vendor_id]']=this.vendor?this.vendor.id:null
      this.$refs.basetable.fetchData()
    },
    reset(){
      this.vendor = null
      this.show()
    }
  }
}
</script>
<style lang="">

</style>
